<script lang="tsx">
import type { SlotsType, VNode } from 'vue'
import type { CheckboxValue } from '@core/app/composables/components'
import type { BaseUiCheckboxProps } from '../BaseUiCheckbox.vue'
import type { _BaseUiFormGroupProps, BaseUiFormGroupSlots } from './BaseUiFormGroup.vue'
import { BaseUiCheckbox, BaseUiFormGroup } from '#components'
import type { FormFieldObject } from '@core-types/form'
import { getBaseUiFormGroupRuntimeProps } from './BaseUiFormGroup.vue'
import { getBaseUiCheckboxRuntimeProps } from '../BaseUiCheckbox.vue'

type _BaseUiFormCheckboxProps = {
    labelClass?: string
    inputClass?: string
}

export type BaseUiFormCheckboxProps = _BaseUiFormCheckboxProps & BaseUiCheckboxProps & Omit<
    _BaseUiFormGroupProps, 'noLabelElement' | 'descriptionId'
>

type BaseUiFormCheckboxSlots<T> = Omit<BaseUiFormGroupSlots, 'default'> & {
    'default': {}
    'below-label': {}
}

type ComponentOptions = {}

export function defineComponentBaseUiFormCheckbox<T>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiFormCheckboxProps, BaseUiFormCheckboxSlots<T>>) {

    const { noLabelElement, descriptionId, ...BaseUiFormGroupRuntimeProps } = getBaseUiFormGroupRuntimeProps(options)

    return defineComponent(
        (props: BaseUiFormCheckboxProps, ctx) => {
            return () => (
                <BaseUiFormGroup
                    form={props.form}
                    help={props.help}
                    descriptionAbove={props.descriptionAbove}
                    descriptionBelow={props.descriptionBelow}
                    hideRequired={props.hideRequired}
                    label={props.label}
                    noLabelElement={undefined}
                    errorId={props.errorId}
                    descriptionId={props.descriptionId}
                    disabled={props.disabled}
                    loading={props.loading}
                    required={props.required}
                    id={props.id}
                    ariaLabel={props.ariaLabel}
                    ariaInvalid={props.ariaInvalid}
                >
                    {(slotData: BaseUiFormGroupSlots['default']) => [

                        slotData.renderAboveDescription(),

                        (
                            <div class="sim-form-checkbox__container">
                                <BaseUiCheckbox
                                    modelValue={props.modelValue}
                                    onUpdate:modelValue={(val: CheckboxValue) => ctx.emit('update:modelValue', val)}
                                    form={props.form}
                                    onUpdate:form={(val: FormFieldObject<CheckboxValue>) => ctx.emit('update:form', val)}

                                    class={props.inputClass}
                                    type={props.type}

                                    id={slotData.inputId}
                                    value={props.value}
                                    name={props.name}
                                    clearable={props.clearable}
                                    disabled={props.disabled}
                                    loading={props.loading}
                                    required={slotData.isInputRequired}
                                    descriptionId={slotData.inputDescriptionIds}
                                    ariaLabel={props.ariaLabel}
                                    ariaInvalid={slotData.isInputAriaInvalid}

                                    onChange={(value: CheckboxValue) => ctx.emit('change', value)}
                                    onChecked={() => ctx.emit('checked')}
                                    onUnchecked={() => ctx.emit('unchecked')}
                                />

                                {
                                    slotData.renderLabel(
                                        (
                                            renderSlot(ctx.slots.default, options?.slots?.default, {}, (
                                                <>
                                                    {props.label}
                                                </>
                                            ))
                                        ) as VNode,
                                        {
                                            textNormal: true,
                                            hasDefaultSlotContent: ctx.slots.default !== undefined || !!options?.slots?.default,
                                            class: ['!cursor-pointer', props.labelClass].filter(Boolean).join(' '),
                                            slotBelow: renderSlot(ctx.slots['below-label'], options?.slots?.['below-label'], {}) as VNode,
                                        }
                                    )
                                }

                            </div>
                        ),

                        slotData.renderBelowDescriptionAndError(),

                    ]}
                </BaseUiFormGroup>
            )
        },
        {
            props: {
                ...defineRuntimeProps<_BaseUiFormCheckboxProps>({
                    labelClass: { type: String },
                    inputClass: { type: String },
                }),
                ...getBaseUiCheckboxRuntimeProps(options),
                ...BaseUiFormGroupRuntimeProps,
            },
            slots: Object as SlotsType<BaseUiFormCheckboxSlots<T>>,
            emits: {
                'update:modelValue': (val: CheckboxValue) => true,
                'update:form': (val: FormFieldObject<CheckboxValue>) => true,
                'checked': () => true,
                'unchecked': () => true,
                'change': (value: CheckboxValue) => true,
            },
        }
    )
}

export default defineComponentBaseUiFormCheckbox()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormCheckbox.scss" as *;

</style>
